<template>
  <div class="contact">
    <div class="contact_banner">
      <h1>诚信赢得客户 质量取信客户 服务巩固客户</h1>
    </div>
    <!-- contact_banner end -->

    <b-container fluid="xl" class="contace_map">
      <baidu-map
        class="contace_mapBox"
        :center="{ lng: 113.16740029847308, lat: 23.055850960577523 }"
        :zoom="18"
      >
        <bm-marker
          :position="{ lng: 113.16740029847308, lat: 23.055850960577523 }"
          :dragging="true"
          animation="BMAP_ANIMATION_BOUNCE"
        >
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
          <bm-label
            content="<span class='mapText'>拓越团队地址<br> 期待你的联系 </span>"
            :labelStyle="{
              color: '#409EFF',
              fontSize: '24px',
              borderColor: '#fff',
            }"
            :offset="{ width: 35, height: -30 }"
          />
        </bm-marker>
      </baidu-map>
    </b-container>
    <!-- contace_map end -->

    <b-container fluid="xl" class="contace_put">
      <b-card
        title="留下您的电话"
        tag="article"
        style="max-width: "
        class="mb-2"
      >
        <b-card-text> 让我们专业的团队联系您 </b-card-text>
        <!-- <b-button href="#" variant="primary">Go somewhere</b-button> -->
      </b-card>
      <!-- <b-form-input v-model="PubTitle" placeholder="PubTitle- your name"></b-form-input>
<b-form-input v-model="PubContent" placeholder="PubContent- your name"></b-form-input> -->
      <hr />

      <b-form-input
        v-model="form.content"
        placeholder="客户留言"
      ></b-form-input>
      <b-form-input
        v-model="form.name"
        placeholder="敬请下尊姓大名我们将严格保证你的隐私"
      ></b-form-input>
      <b-form-input
        v-model="form.telephone"
        placeholder="请输入手机号方便我们联系您"
      ></b-form-input>
      <!-- <x-input title="姓名" placeholder="请输入姓名" v-model="form.name"></x-input>
<x-input title="手机" placeholder="请输入手机号" v-model="form.mobile"></x-input> -->
      <b-button @click="submit()" variant="info btn-block">
        <!-- <i class="zi zi_click"></i> -->
        提交
      </b-button>
    </b-container>
  </div>
</template>
<script>
import {WOW} from "wowjs";

export default {
  data() {
    return {
        form: {}
    }
  },
  metaInfo: {
    title: "联系我们",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
      },
      {
        vmid: "description",
        name: "description",
        content: "联系拓越团队最快方式",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content: "广东拓越发展有限公司",
      },
    ],
  },
  methods: {
    async submit() {
      var ref = this;
      if (ref.Regex.isEmpty(ref.form.name)) {
        ref.util.alert("姓名不能为空");
        return false;
      }
      if (!ref.Regex.isMobilePhone(ref.form.telephone)) {
        ref.util.alert("手机号格式不正确 ");
        return false;
      }
      await this.$http.post("/message", ref.form).then((e) => {
        if (e.status == 200) {
          alert("我们会第一时间联系你");
        }
      });
    },
      async mounted() {
          let _this = this;
          let ziji = await this.$http.get("/contact/add",{},_this.form);
          if (ziji.status == 200) {
              console.log(ziji.data.data.form);
              _this.form = ziji.data.data;
          }
          // 动画重启
          this.$nextTick(() => {
              // 在dom渲染完后,再执行动画
              var wow = new WOW({
                  live: false,
              });
              wow.init();
          });
      },
  },
};
</script>	
